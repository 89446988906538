<template>
  <div>
      <OrderLoader />
  </div>
</template>

<script>
import OrderLoader from '@/components/OrderLoader.vue'

export default {
    mounted() {
        window.dataLayer.push({
            event: 'download_app'
        });

        const user_agent = navigator.userAgent || navigator.vendor;

        setTimeout(this.openSecondaryPage, 2500);

        if (/iPhone/i.test(user_agent)) {
            window.location.href = 'https://apps.apple.com/ua/app/qrdelivery/id1507792224?l=uk';
            return
        }

        if (/Android/i.test(user_agent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.webqrdelivery.qrdelivery';
            return
        }
        
        return
    },
    methods: {
        openSecondaryPage() {
            if (this.isMobile) {
                this.$router.push('/marketplace');  
            } else {
                window.location.href = "https://qrwaiter.com.ua/visitor/";
            }
        }
    },
    components: {
        OrderLoader
    }
}
</script>

<style>

</style>